import React from 'react';
import clsx from 'clsx';

import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Footnotes from '../components/Footnotes/Footnotes';
import Title from '../components/Title/Title';
import Tabs from '../components/Tabs/Tabs';
import TreatmentCalendar from '../components/TreatmentCalendar/TreatmentCalendar';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';
import Columns from '../components/Columns/Columns';
import Card from '../components/Card/Card';

import * as styles from '../styles/pages/receiving-treatment.module.css';

import heroImg from '../images/receivingTreatment/hero.png';
import first6CyclesMImg from '../images/receivingTreatment/first-6-cycles-m.png';
import first6CyclesImg from '../images/receivingTreatment/first-6-cycles.png';
import minutes60Img from '../images/receivingTreatment/60minutes.png';
import minutes30Img from '../images/receivingTreatment/30minutes.png';
import teamCoreImg from '../images/receivingTreatment/core-team.png';
import teamSpecialistImg from '../images/receivingTreatment/specialist-team.png';
import teamSupportiveCareImg from '../images/receivingTreatment/supportive-care-team.png';
import dailyActivitiesImg from '../images/receivingTreatment/daily-activities.png';
import restImg from '../images/receivingTreatment/rest.png';
import mealsImg from '../images/receivingTreatment/meals.png';
import scheduleImg from '../images/receivingTreatment/schedule.png';
import chatImg from '../images/receivingTreatment/chat.png';
import childCareImg from '../images/receivingTreatment/child-care.png';
import financialAssistanceImg from '../images/receivingTreatment/financial-assistance.png';
import calendar2Img from '../images/calendar-2-weeks.png';
import calendar3Img from '../images/calendar-3-weeks.png';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Receiving treatment | Loqtorzi™ Patient website</title>
      <meta name='description'content='Understand the Loqtorzi™ treatment plan and how to prepare for your infusion'/>
    </>
  )
}

export default function ReceivingTreatment() {
  const tabContent = [
    {
      title: <span className='text-center'>LOQTORZI™ + <br />chemotherapy*</span>,
      text: <section className='box-shadow pb-[4rem] tablet:pt-[4.2rem] tablet:pb-[3.3rem] tablet:pl-[5.2rem]'>
        <Title>
          <h4>What is the treatment schedule for LOQTORZI™?</h4>
        </Title>

        <TreatmentCalendar
          className={'mt-[2rem]'}
          text={<span>LOQTORZI™ is used in combination with chemotherapy,* then continued <span className='text-gold-600'>chemo free</span>, as your first treatment when your NPC has spread to other parts of your body or has returned in nearby tissues.</span>}
          dosis={'+ chemotherapy* may be'}
          weeks={3}
          img={{
            src: calendar3Img,
            alt: 'Calendar 3 weeks icon'
          }}
          iconLastText={'for up to 6 cycles, then LOQTORZI™ is continued without chemotherapy* for up to 2 years'}
          />

        <div className='mt-[2rem] tablet:mt-[4rem]'>Your doctor will monitor your condition to ensure the treatment is working and your side effects are manageable. They will decide how many treatments may be right for you and may delay or completely stop your treatment if you have severe side effects.</div>

        <div className='mt-[2.4rem] font-MontserratSemiBold text-[1.5rem] text-blue-600 tablet:mt-[5rem] tablet:text-[2rem] tablet:text-center'>
          TREATMENT SCHEDULE <br className='tablet:hidden'/>FOR LOQTORZI™ IN THE STUDY
        </div>

        <div className='mt-[1.1rem] tablet:mt-[2.3rem]'>
          <picture>
            <source media="(max-width:767px)" srcSet={ first6CyclesMImg } />
            <img src={ first6CyclesImg } alt={ 'First 6 cycles' } />
          </picture>
        </div>

        <Footnotes className={'mt-[2rem]'} items={[
          {
            bullet: '*',
            text: <span>Chemotherapy is cisplatin and gemcitabine.</span>
          },
          {
            bullet: '',
            text: <span>IV=intravenous.</span>
          }
        ]} />
      </section>
    },
    {
      title: <span className='text-center'>LOQTORZI™ <br />alone</span>,
      text: <section className='box-shadow tablet:py-[4.2rem] tablet:pl-[5.2rem]'>
        <Title>
          <h4>What is the treatment schedule for LOQTORZI™?</h4>
        </Title>

        <TreatmentCalendar
          wrapperClassName={'!flex-row desktop:!justify-start'}
          dosis={'(3 mg/kg) alone is'}
          text={ null }
          weeks={2}
          img={{
            src: calendar2Img,
            alt: 'Calendar 2 weeks icon'
          }}
          />

        <div className='mt-[2rem] tablet:mt-[4rem]'>Your doctor will monitor your condition to ensure the treatment is working and your side effects are manageable. They will decide how many treatments may be right for you and may delay or completely stop your treatment if you have severe side effects.</div>

        <Footnotes className={'mt-[2rem]'} items={[
          {
            bullet: '',
            text: <span>IV=intravenous.</span>
          }
        ]} />
      </section>
    }
  ];
  return (
    <Layout>
      <Hero image={ { src: heroImg, alt:'Hero receiving treatment' } }>
        <Title>
          <h1>Receiving Treatment</h1>
        </Title>
      </Hero>

      <GlobalWrapper className={'mobileFull'}>
        <Tabs className={'mt-[1.5rem]'} headerClassName={'mx-[1.5rem]'} content={ tabContent } />
      </GlobalWrapper>

      <GlobalWrapper>
        <Title className={'mt-[3.7rem] tablet:mt-[6.7rem]'}>
          <h4>Getting ready for your infusion</h4>
        </Title>

        <div className='mt-[2.5rem] tablet:mt-[2rem]'>LOQTORZI™ is given through a vein as an IV infusion. A registered nurse will administer your treatment at your doctor’s office or an infusion center. They can also help answer any questions you may have.</div>

        <Columns className={'mt-[2.5rem] tablet:mt-[3.5rem]'} colClassName={'items-center'}>
          <div className=''>
            <div className='flex items-center gap-[1.5rem]'>
              <div className='w-[9.1rem] tablet:w-[13.4rem]'>
                <img src={ minutes60Img } alt='Your first infusion may take 60 minutes' />
              </div>
              <div className='text-blue-600'>
                <div className='font-MontserratMedium text-[1.6rem] desktop:text-[1.8rem]'>Your first infusion <br />will take at least </div>
                <strong className='text-[3.2rem] desktop:text-[3.6rem]'>60 minutes</strong>
              </div>
            </div>
          </div>
          <div className='mt-[3rem] tablet:mt-0'>
            <div className='flex items-center gap-[1.5rem]'>
              <div className='w-[9.1rem] tablet:w-[13.4rem]'>
                <img src={ minutes30Img } alt='After that, infusions may take' />
              </div>
              <div className='text-blue-600'>
                <div className='font-MontserratMedium text-[1.6rem] desktop:text-[1.8rem]'>
                If no infusion-related <br />
                reactions occurred during <br />
                the first infusion, following <br />
                infusions may take
                </div>
                <strong className='text-[3.2rem] desktop:text-[3.6rem]'>30 minutes</strong>
              </div>
            </div>
          </div>
        </Columns>

        <p className='mt-[3rem] tablet:mt-[3.6rem]'><strong>Infusion reactions can sometimes be severe or life-threatening.</strong> Signs or symptoms of infusion reactions may include:</p>
        <ul className={ clsx(styles.listColumns, 'bullet-list bullet-list--blue') }>
          <li>Chills or shaking</li>
          <li>Itching or rash</li>
          <li>Flushing</li>
          <li>Shortness of breath or wheezing</li>
          <li>Dizziness</li>
          <li>Feeling like passing out</li>
          <li>Fever</li>
          <li>Back pain</li>
        </ul>

        <Title className={'mt-[4.8rem] tablet:mt-[5.5rem]'}>
          <h4>Get to know your care team</h4>
        </Title>

        <p className='mt-[2.5rem] tablet:mt-[1.5rem]'>Your care will be led by your oncologist, who is responsible for your treatment plan, but every member of your team plays an important role.</p>
        <p>The list below may include some of the team members involved in your care.</p>
      </GlobalWrapper>

      <GlobalWrapper className={'equalMargin'}>
        <Columns className={'mt-[2.5rem] tablet:gap-[1.5rem] tablet:mt-[3.5rem] desktop:gap-[5.7rem]'}>
          <Card
            className={'!justify-start pb-[3.1rem]'}
            image={{
              className: 'w-[9.1rem]',
              src: teamCoreImg,
              alt: 'Core team'
            }}
            title='Core team'
            text={
              <ul className='listDisc'>
                <li>Medical oncologist</li>
                <li>Primary care provider</li>
                <li>Clinical nurse specialist</li>
                <li>Radiation oncologist</li>
                <li>Oncology nurse navigator</li>
              </ul>
            }
          />

          <Card
            className={'!justify-start mt-[4.5rem] pb-[2.1rem] tablet:mt-0'}
            image={{
              className: 'w-[14.5rem]',
              src: teamSpecialistImg,
              alt: 'Specialist team'
            }}
            title='Specialist team'
            text={
              <ul className='listDisc'>
                <li>Ear, nose, and throat doctor</li>
                <li>Dentist</li>
                <li>Radiation therapist</li>
              </ul>
            }
          />

          <Card
            className={'!justify-start mt-[5.8rem] pb-[2.6rem] tablet:mt-0 tablet:pb-[2.1rem]'}
            image={{
              className: 'w-[13.6rem]',
              src: teamSupportiveCareImg,
              alt: 'Supportive care team'
            }}
            title='Supportive care team'
            text={
              <ul className='listDisc'>
                <li>Caregiver</li>
                <li>Speech therapist</li>
                <li>Nutritionist or dietitian</li>
                <li>Oncology social worker</li>
                <li>Psychiatrist or therapist</li>
              </ul>
            }
          />

        </Columns>
      </GlobalWrapper>

      <GlobalWrapper>
        <Title className={'mt-[4.6rem] tablet:mt-[7.9rem]'}>
          <h4>What happens during and after treatment?</h4>
        </Title>
        <div className='mt-[2.3rem]'>
          <strong>Managing side effects</strong>
          <p className='mt-[2.5rem] tablet:mt-[2rem]'>You may experience bruising or irritation where you received the infusion. If you notice any reactions to your treatment or feel pain, tell your nurse or healthcare provider right away.</p>
          <p>Call or visit your healthcare provider right away if you experience new or worsening symptoms or treatment side effects, including shortness of breath or wheezing, rash, and fever.</p>
        </div>
        <div className='mt-[3.6rem]'>
          <strong>Living with NPC</strong>
          <p className='mt-[2.6rem] tablet:mt-[2rem]'>Living with your disease may require some adjustments to your daily life, but there are things you can do to help.</p>
        </div>
      </GlobalWrapper>

      <GlobalWrapper className={'equalMargin'}>
        <Columns className={'mt-[2.5rem] tablet:gap-[1.5rem] tablet:mt-[3.5rem] desktop:gap-[5.7rem]'}>
          <Card
            className={'!justify-start pb-[2.4rem]'}
            image={{
              className: 'w-[9.2rem]',
              src: dailyActivitiesImg,
              alt: 'Core team'
            }}
            text={
              <div>Depending on how you are feeling, you may be able to continue with your daily activities such as going to work and exercising. </div>
            }
          />

          <Card
            className={'!justify-start mt-[4.8rem] pb-[1.6rem] tablet:mt-0'}
            image={{
              className: 'w-[7.4rem]',
              src: restImg,
              alt: 'Specialist team'
            }}
            text={
              <div>Remember, it’s important to get plenty of rest to help you take on each day.</div>
            }
          />
          <div></div>
        </Columns>
      </GlobalWrapper>

      <GlobalWrapper>
        <div className='mt-[5.6rem] tablet:mt-[7.3rem]'>
          <strong>Involving your family, friends, or loved ones</strong>
          <div className='mt-[2.5rem] tablet:mt-[1.8rem]'>Handling your diagnosis can feel overwhelming, but having the support of family, friends, or loved ones can help with:</div>
        </div>
      </GlobalWrapper>

      <GlobalWrapper className={'equalMargin'}>
        <Columns className={'mt-[2.5rem] tablet:gap-[1.5rem] tablet:mt-[3.7rem] desktop:gap-[5.7rem]'}>
          <Card
            className={'!justify-start pb-[2.1rem]'}
            image={{
              className: 'w-[8.2rem]',
              src: mealsImg,
              alt: 'Meals'
            }}
            text={
              <div>Preparing meals and helping with housework, yard work, and laundry</div>
            }
          />

          <Card
            className={'!justify-start mt-[5.1rem] pb-[2.1rem] tablet:mt-0'}
            image={{
              className: 'w-[7.4rem]',
              src: scheduleImg,
              alt: 'Schedule'
            }}
            text={
              <div>Scheduling appointments and organizing medical records</div>
            }
          />

          <Card
            className={'!justify-start mt-[5.2rem] pb-[2.6rem] tablet:mt-0 tablet:pb-[2.1rem]'}
            image={{
              className: 'w-[8.4rem]',
              src: chatImg,
              alt: 'Chat'
            }}
            text={
              <div>Communicating with your healthcare team</div>
            }
          />

        </Columns>
        <Columns className={'mt-[5.2rem] tablet:gap-[1.5rem] desktop:gap-[5.7rem]'}>
          <Card
            className={'!justify-start pb-[1.6rem]'}
            image={{
              className: 'w-[9.2rem]',
              src: childCareImg,
              alt: 'Child care'
            }}
            text={
              <div>Arranging for transportation or child care during your appointments and infusions</div>
            }
          />

          <Card
            className={'!justify-start mt-[5.2rem] pb-[2.1rem] tablet:mt-0'}
            image={{
              className: 'w-[7.1rem]',
              src: financialAssistanceImg,
              alt: 'Financial Assistance'
            }}
            text={
              <div>Locating supportive resources and financial assistance</div>
            }
          />
          <div />
        </Columns>
      </GlobalWrapper>

      <GlobalWrapper className={'desktop:pb-[4.5rem]'}>
        <ColumnsConnections className={'mt-[4.9rem] tablet:mt-[5.6rem] desktop:mt-[12rem]'}/>
      </GlobalWrapper>
    </Layout>
  );
}
