import React from 'react';
import * as styles from './Card.module.css';
import clsx from 'clsx';

export default function Card({ className, image, title= null, text }) {
  return (
    <div className={clsx(styles.card, className)}>
      <div className={ styles.image }>
        <img className={ image.className } src={ image.src } alt={ image.alt } />
      </div>
      <div>
        {
          title && <strong>{ title }</strong>
        }
        {
          text
        }
      </div>
    </div>
  );
}