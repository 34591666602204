import React, { useState, useEffect, useRef } from 'react';
import * as styles from './Spikes.module.css';
import clsx from 'clsx';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';

export default function Spikes({ className, wrapperClassName, isHome=false }) {
  const [isFollowing, setIsFollowing] = useState(true);

  const spikesRef = useRef(null);

  useEffect(() => {

  const isBrowser = typeof window !== 'undefined';

  if(isBrowser) {
    const isiFull = document.querySelector('.isi-full');
    const handleScroll = event => {
      if(window.scrollY >= (isiFull.offsetTop - (257 + spikesRef.current.offsetHeight + (isHome ? 250 : 0)))) {
        setIsFollowing(false);
      } else {
        setIsFollowing(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }
  }, [isHome]);

  return (
    <GlobalWrapper className={clsx(styles.wrapper, wrapperClassName, isFollowing ? styles.setFixed : styles.end, 'mobileFull')}>
      <div ref={spikesRef} className={ clsx(styles.spikes, className)}></div>
    </GlobalWrapper>
  );
}