import React from 'react';
import clsx from 'clsx';
import * as styles from './Hero.module.css';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';
import Spikes from '../Spikes/Spikes';

export default function Hero({ className, image, children }) {
  return (
    <>
    <section className={ clsx(className) }>
      <GlobalWrapper>
        <div className={ styles.container }>
          <div className={ styles.image }>
            <img src={ image.src } alt={ image.alt } />
          </div>
          <div className={ styles.text }>
            { children }
          </div>
        </div>
      </GlobalWrapper>
    </section>
    <Spikes wrapperClassName={styles.spikes}/>
    </>
  );
}