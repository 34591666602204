import React from 'react';
import clsx from 'clsx';

import Columns from '../Columns/Columns';

export default function TreatmentCalendar({ className, wrapperClassName, text, weeks, img, iconLastText, dosis }) {
  return (
    <div className={ clsx(className) }>
      <Columns className={clsx('tablet:flex-col desktop:mt-[3.1rem] large:flex-row-reverse large:justify-end large:items-center large:gap-0', wrapperClassName)}
        colClassName={'!flex-initial'}>
        { text &&
          <div className='large:ml-[7.2rem] large:max-w-[56rem]'>
            <div>{ text }</div>
          </div>
        }
        <div className='mt-[2rem] large:mt-0'>
          <div className='flex gap-[2.1rem] justify-start'>
            <div className='min-w-[8.1rem] desktop:min-w-[10.6rem]'>
              <img className='w-[8.1rem] h-auto desktop:w-[10.6rem]' src={ img.src } alt={ img.alt } />
            </div>
            <div className={clsx(iconLastText ? 'justify-between' : 'justify-center', 'flex flex-col w-[65%] max-w-[33rem] leading-[1.13] text-blue-600 desktop:text-[1.8rem] large:w-full')}>
              <div>LOQTORZI™ { dosis } given as&nbsp;an IV infusion&nbsp;every</div>
              <div className='font-MontserratBold text-[3.2rem] desktop:text-[3.6rem] whitespace-nowrap'>{ weeks } weeks</div>
              { iconLastText }
            </div>
          </div>
        </div>
      </Columns>
    </div>
  );
}