import React, { Children } from 'react';
import * as styles from './Columns.module.css';
import clsx from 'clsx';

export default function Columns({ className, colClassName, children, yellowPipe=false }) {
  const arrayChildren = Children.toArray(children);

  return (
    <section className={ clsx(styles.columns, className, yellowPipe && styles.yellowPipe) }>
      {
        Children.map(arrayChildren, (child, index) =>
          <div className={ clsx(styles.column, colClassName) } key={ index }>
            { child }
          </div>
        )
      }
    </section>
  );
}