import React from 'react';
import * as styles from './Title.module.css';
import clsx from 'clsx';

export default function Title({ className, children}) {
  return (
    <div className={ clsx(styles.title, className) }>
      { children }
    </div>
  );
}