import React from 'react';
import * as styles from './Footnotes.module.css';
import clsx from 'clsx';

export default function Footnotes({ className, items }) {

  return (
    <div className={ clsx(styles.footnotes, className) }>
      {
        items.map((item, index) =>
          <div key={ index } className={ styles.footnote }>
            <span className={ styles.bullet }>{ item.bullet }</span>
            <span>{ item.text }</span>
          </div>
        )
      }
    </div>
  );
}