import React from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import * as styles from './ColumnsConnections.module.css';
import ArrowImg from '../../images/logo-arrow.inline.svg';
import ArrowDarkImg from '../../images/logo-arrow-dark.inline.svg';

export default function ColumnsConnections({ className, data=null, darkMode=false }) {

  const content = [
    {
      title: 'Stay connected',
      link: '/stay-in-touch/',
      text: 'Keep yourself updated and informed by joining our community for people living with NPC.',
      className: 'is-active'
    },
    {
      title: 'See side effects',
      link: '/loqtorzi-side-effects/',
      text: 'Learn about the possible side effects of LOQTORZI treatment.'
    },
    {
      title: 'Get resources',
      link: '/loqtorzi-solutions/',
      text: 'Find educational tools and resources to help support you on your treatment journey.'
    }
  ];

  return (
    <section className={ clsx(styles.columnsConnections, className, darkMode && styles.darkMode) }>
      {
        (data || content).map((item, index) => <div className={ clsx(styles.box, item.className) } key={ index }>
          <div className={ clsx(styles.container, item.classNameContainer) }>
            <button className={ styles.title } onClick={ () => { navigate(item.link) } }>
              { item.title }
              { darkMode ? <ArrowDarkImg className={ styles.arrow }/>  : <ArrowImg className={ styles.arrow }/> }
            </button>
            <div className={ styles.text }>
              { item.text }
            </div>
          </div>
        </div>)
      }
    </section>
  );
}